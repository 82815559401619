import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from '../components';

const StyledSection = styled.section`
  text-align: center;
`;

export const External = ({ pageContext: { pageUrl } }) => {
  useEffect(() => {
    console.log(`Replace location ${pageUrl}`);
    window.location.replace(pageUrl);
  });

  return (
    <StyledSection>
      <h3>Estamos redirecionando a página...</h3>
      <Loader />
    </StyledSection>
  );
};

export default External;
